import { createGlobalStyle } from 'styled-components';

const FontsGlobal = createGlobalStyle`
    @font-face {
        font-family: 'CassannetPlus';
        src: url('./fonts/CassannetPlus-Bold.otf') format('opentype');
        font-display: swap;
    }

    @font-face {
        font-family: 'FinoSansTitle-Thin';
        src: url('./fonts/FinoSansTitle-Thin.otf') format('opentype');
        font-display: swap;
    }

    @font-face {
        font-family: 'HeldaneText-Regular';
        src: url('./fonts/HeldaneText-Regular.otf') format('opentype');
        font-display: swap;
    }

    @font-face {
        font-family: 'HeldaneText-RegularItalic';
        src: url('./fonts/HeldaneText-RegularItalic.otf') format('opentype');
        font-display: swap;
    }

    @font-face {
        font-family: 'SweetSansPro-Bold';
        src: url('./fonts/SweetSansPro-Bold.otf') format('opentype');
        font-display: swap;
    }

    @font-face {
        font-family: 'SweetSansPro-Regular';
        src: url('./fonts/SweetSansPro-Regular.otf') format('opentype');
        font-display: swap;
    }
`;


export default FontsGlobal;