import React from 'react';

const TOSPage: React.FC = () => {
    return (
        <div
            className='terms-of-service'
        >
            <h2>TÉRMINOS Y CONDICIONES</h2>
            <h3>www.gothamcitymidnight.com</h3>
            <p>
                <strong>INTRODUCCIÓN.</strong> El presente documento (conjuntamente con todos los documentos en él
                mencionados) establece las condiciones por las que se rige el uso de este sitio web
                (gothamcitymidnight.com) y la compra de productos en la misma (en adelante, las "Condiciones"). 
                Le solicitamos leer atentamente las presentes Condiciones, nuestra Política de Cookies y nuestra Política
                de Privacidad (conjuntamente, las “Políticas de Protección de Datos”) antes de usar este sitio web. Al
                utilizar este sitio web o hacer un pedido a través del mismo usted consiente quedar vinculado por estas
                Condiciones y por nuestras Políticas de Protección de Datos, por lo que, si no está usted de acuerdo con
                todas las Condiciones y con las Políticas de Protección de Datos, no debe usar este sitio web. Estas
                Condiciones podrían ser modificadas, por lo que es su responsabilidad leerlas periódicamente, ya que las
                condiciones vigentes en el momento de celebración de cada Contrato (tal y como éste se define más
                adelante) o en defecto de éste, en el momento de uso de la página web serán las que le resulten
                aplicables. Si tiene alguna pregunta relacionada con las Condiciones o las Políticas de Protección de
                Datos puede ponerse en contacto (tal y como se define más adelante) a través de las vías de contacto
                descritas en este sitio web. El Contrato (tal y como se define más adelante) podrá formalizarse, a su
                elección, en cualquiera de los idiomas en los que las Condiciones están disponibles en este sitio web.
            </p>
            <p>
                <strong>DATOS DE LA EMPRESA.</strong> La venta de artículos a través de este sitio web es realizada bajo
                la denominación comercial “GOTHAM CITY™ MIDNIGHT” por NICE LIKE THAT, S.A. DE C.V. en su carácter de
                licenciatario autorizado de WARNER BROS DISCOVERY LLC, con domicilio en Lago Zúrich No. 245, Local
                B-02-A, Col. Ampliación Granada, alcaldía Miguel Hidalgo, Ciudad de México; RFC: NLT140613LK0 (en
                adelante, “LA EMPRESA”).
            </p>
            <p>
                <strong>SUS DATOS Y SUS VISITAS A ESTE SITIO WEB.</strong> La información o datos personales que
                facilite a la Empresa sobre usted serán tratados con arreglo a lo establecido en las Políticas de
                Protección de la Información, al hacer uso de este sitio web usted consiente el tratamiento de dicha
                información y datos y declara que toda la información o datos que nos facilite son veraces y se
                corresponden con la realidad.
            </p>

            <p>
                <strong>USO DEL SITIO WEB.</strong> Al hacer uso de este sitio web y realizar pedidos a través del mismo
                se compromete a: i. Hacer uso de este sitio web únicamente para realizar consultas o pedidos legalmente
                válidos. ii. No realizar ningún pedido falso o fraudulento. Si razonablemente se pudiera considerar que
                se ha hecho un pedido de esta índole autoriza a La Empresa a anularlo e informar a las autoridades
                pertinentes. iii. Facilitar su dirección de correo electrónico, nombre completo y/u otros datos de
                contacto de forma veraz y exacta. Asimismo, consiente que dicha información podrá ser usada como
                información de contacto de ser necesario (ver Política de Privacidad). De no facilitar la información
                requerida no será posible realizar su pedido. Al realizar un pedido a través de este sitio web, usted
                declara ser mayor de 18 años y tener capacidad legal para celebrar contratos.
            </p>

            <p>
                <strong>DISPONIBILIDAD DEL SERVICIO.</strong> Los artículos que se ofrecen a través de este sitio web
                están únicamente disponibles dentro de la República Mexicana, salvo aquellas áreas o zonas que por la
                dificultad de acceso, comunicaciones o consideraciones similares se justifique que dicha oferta no esté
                disponible.
            </p>
            <p><strong>PRODUCTOS.</strong> Todas las descripciones de productos y/o servicios, informaciones y
                materiales que figuran en este sitio web se suministran "como cuerpo cierto " y sin garantías expresas o
                implícitas sobre los mismos salvo las establecidas legalmente. En este sentido, si usted contrata como
                consumidor y usuario, la Empresa se obliga a entregarle artículos y/o servicios que sean conformes con
                lo descrito, respondiendo frente a usted de cualquier falta de conformidad que exista en el momento de
                la entrega del producto. Con el alcance que permita la ley, se excluyen todas las garantías, salvo
                aquellas que no puedan ser legítimamente excluidas frente a los consumidores y usuarios. Lo dispuesto en
                la presente cláusula no afectará a sus derechos como consumidor y usuario, ni a su derecho a desistir de
                la compra.</p>
            <p><strong>HIPERVÍNCULOS DESDE NUESTRA PÁGINA WEB.</strong> En el caso de que esta página web contenga
                hipervínculos (links) a otras páginas web y materiales de terceros, dichos links se facilitan únicamente
                a efectos informativos, sin que se tenga control alguno sobre el contenido de dichas páginas web o
                materiales. Por lo tanto, se niega responsabilidad alguna por cualquier daño, perjuicio o pérdida
                derivados de su uso.</p>
            <p><strong>PROPIEDAD INTELECTUAL.</strong> Usted reconoce y consiente que todo copyright, marca registrada y
                demás derechos de propiedad intelectual sobre los materiales o contenidos que se aportan como parte de
                la página web corresponden en todo momento al propietario de dicho contenido y/o al otorgante de la
                licencia para su uso. Usted podrá hacer uso de dicho material únicamente en la forma en que le sea
                expresamente autorizado para su uso. Esto no le impedirá utilizar este sitio web en la medida necesaria
                para copiar la información sobre su pedido o datos de Contacto.</p>
            <p><strong>VIRUS, PIRATERÍA Y OTROS ATAQUES INFORMÁTICOS.</strong> Usted no debe realizar un uso indebido de
                este sitio web mediante la introducción intencionada en la misma de virus, troyanos, gusanos, bombas
                lógicas o cualquier otro programa o material tecnológicamente perjudicial o dañino. Usted no tratará de
                tener acceso no autorizado a este sitio web, al servidor en que dicha página se encuentra alojada o a
                cualquier servidor, ordenador o base de datos relacionada con este sitio Usted se compromete a no atacar
                este sitio web a través de un ataque de denegación de servicio o de un ataque de denegación de servicio
                distribuido y/o cualesquiera otros ataques cibernéticos. El incumplimiento de esta cláusula podría
                llevar aparejada la comisión de infracciones tipificadas por la normativa aplicable. Cualquier
                incumplimiento de dicha normativa será notificado a las autoridades competentes y se cooperará con ellas
                para descubrir la identidad del atacante. Asimismo, en caso de incumplimiento de la presente cláusula,
                dejará inmediatamente de estar autorizado a usar este sitio web. No seremos responsables de cualquier
                daño o pérdida resultante de un ataque de denegación de servicio, virus o cualquier otro programa o
                material tecnológicamente perjudicial o dañino que pueda afectar a su ordenador, equipo informático,
                datos o materiales como consecuencia del uso de este sitio web o de la descarga de contenidos de la
                misma o a los que la misma redireccione. </p>
            <p><strong>COMUNICACIONES POR ESCRITO.</strong> La normativa aplicable exige que parte de la información o
                comunicaciones que le sea enviada sean por escrito. Mediante el uso de este sitio web, usted acepta que
                la mayor parte de dichas comunicaciones con la Empresa sean electrónicas. Nos pondremos en contacto con
                usted por correo electrónico o SMS o se facilitará información colgando avisos en este sitio web. A
                efectos contractuales, usted consiente en usar este medio electrónico de comunicación y reconoce que
                todo contrato, notificación, información y demás comunicaciones que le sean enviadas de forma
                electrónica cumplen con los requisitos legales de ser por escrito. Esta condición no afectará a sus
                derechos reconocidos por ley. <br />
                Las notificaciones que usted envíe deberán enviarse preferiblemente a través de las vías de contacto de
                este sitio web. Con arreglo a lo dispuesto en la cláusula anterior y salvo que se estipule lo contrario,
                le podremos enviar comunicaciones bien al correo electrónico o bien a la dirección postal facilitada por
                usted a la hora de realizar un pedido. Se entenderá que las notificaciones han sido recibidas y han sido
                correctamente hechas en el mismo instante en que se cuelguen en este sitio web, 24 horas después de
                haberse enviado un correo electrónico, o tres días después de la fecha de franqueo de cualquier carta.
                Para probar que la notificación ha sido hecha, será suficiente con probar, en el caso de una carta, que
                tenga la dirección correcta, está correctamente sellada y que fue debidamente entregada en correos o en
                un buzón y, en el caso de un correo electrónico, que el mismo fue enviado a la dirección de correo
                electrónico especificada por el consumidor. </p>
            <p><strong>CESIÓN DE DERECHOS.</strong> Este acuerdo es vinculante tanto para usted como para la Empresa,
                así como para nuestros respectivos sucesores, cesionarios y causahabientes. Usted no podrá transmitir,
                ceder, gravar o de cualquier otro modo transferir un Contrato o alguno de los derechos u obligaciones
                derivados del mismo, sin haber obtenido consentimiento previo por escrito. La Empresa se reserva el
                derecho a transmitir, ceder, gravar, subcontratar o de cualquier otro modo transferir un Contrato o
                alguno de los derechos u obligaciones derivados del mismo, en cualquier momento durante la vigencia del
                Contrato. Para evitar cualquier duda, dichas transmisiones, cesiones, gravámenes u otras transferencias
                no afectarán los derechos que, en su caso, usted, como consumidor, tiene reconocidos por ley, ni
                anularán, reducirán o limitarán de cualquier otra forma las garantías, tanto expresas como tácitas, que
                le pudiesen ser otorgadas. </p>
            <p><strong>ACONTECIMIENTOS FUERA DE NUESTRO CONTROL.</strong> La Empresa no se hace responsable por ningún
                incumplimiento o retraso en el cumplimiento de alguna de las obligaciones que fueron asumidas al amparo
                de un Contrato, cuya causa se deba a acontecimientos que están fuera de control razonable ("Causa de
                Caso Fortuito o Fuerza Mayor"). Las Causas de Caso Fortuito o Fuerza Mayor incluirán cualquier acto,
                acontecimiento, falta de ejercicio, omisión o accidente que esté fuera de nuestro control razonable y
                entre otros, los siguientes: i. Huelgas, cierres patronales u otras medidas reivindicativas. ii.
                Conmoción civil, revuelta, invasión, ataque terrorista o amenaza terrorista, guerra (declarada o no) o
                amenaza o preparativos de guerra. iii. Incendio, explosión, tormenta, inundación, terremoto,
                hundimiento, epidemia o cualquier otro desastre natural. iv. Imposibilidad de uso de trenes, barcos,
                aviones, transportes de motor u otros medios de transporte, públicos o privados. v. robos de mercancía.
                vi. bloqueos de carreteras u otras vías de comunicación. Imposibilidad de utilizar sistemas públicos o
                privados de telecomunicaciones. vii. Actos, decretos, legislación, normativa o restricciones de
                cualquier gobierno o autoridad pública. viii. Huelga, fallos o accidentes de transporte marítimo o
                fluvial, postal o cualquier otro tipo de transporte. Se entenderá que las obligaciones derivadas de
                Contratos quedan suspendidas durante el período en que la Causa de Caso Fortuito o Fuerza Mayor
                continúe, y se dispondrá de una ampliación en el plazo para cumplir dichas obligaciones por un periodo
                de tiempo igual al que dure la Causa de Caso Fortuito o Fuerza Mayor. Se pondrán todos los medios
                razonables para que finalice la Causa de Caso Fortuito o Fuerza Mayor o para encontrar una solución que
                permita cumplir las obligaciones en virtud del Contrato a pesar de la Causa de Caso Fortuito o Fuerza
                Mayor. <br />
                La falta de requerimiento por parte de la Empresa del cumplimiento estricto por su parte de alguna de
                las obligaciones asumidas por usted en virtud de un Contrato o de las presentes Condiciones o la falta
                de ejercicio por parte de la Empresa de los derechos o acciones que pudiesen corresponder en virtud de
                dicho Contrato o de las Condiciones, no supondrá renuncia ni limitación alguna en relación con dichos
                derechos o acciones ni le exonerará a usted de cumplir con tales obligaciones. Ninguna renuncia por
                parte de la Empresa a un derecho o acción concreto supondrá una renuncia a otros derechos o acciones
                derivados del Contrato o de las Condiciones. Ninguna renuncia por parte de la Empresa a alguna de las
                presentes Condiciones o a los derechos o acciones derivados del Contrato surtirá efecto, a no ser que se
                establezca expresamente que es una renuncia y se formalice y se le comunique a usted por escrito de
                conformidad con lo dispuesto en el apartado de Notificaciones anterior. </p>
            <p><strong>NULIDAD.</strong> Si alguna de las presentes Condiciones o alguna disposición de un Contrato
                fuese declaradas nulas y sin efecto por resolución firme dictada por autoridad competente, los restantes
                términos y condiciones permanecerán en vigor, sin que queden afectados por dicha declaración de nulidad.
            </p>
            <p><strong>ACUERDO.</strong> Las presentes Condiciones y todo documento a que se haga referencia expresa en
                las mismas constituyen el acuerdo íntegro existente entre usted y la Empresa en función de la marca del
                producto que haya adquirido y en relación con el objeto de las mismas y sustituyen a cualquier otro
                pacto, acuerdo o promesa anterior convenida entre usted y la Empresa, y verbalmente, por escrito o a
                través de cualquier otro medio. Usted y NICE LIKE THAT, SA DE CV, reconocen haber consentido la
                celebración del Contrato sin haber confiado en ninguna declaración o promesa hecha por la otra parte o
                que pudiera inferirse de cualquier declaración o escrito en las negociaciones entabladas por las partes,
                antes de dicho Contrato, salvo aquello que figura expresamente mencionado en las presentes Condiciones.
                Ni usted ni la Empresa dispondrán de acción frente a cualquier declaración incierta realizada por la
                otra parte, verbal o escrita, con anterioridad a la fecha del Contrato (salvo que se hubiera hecho tal
                declaración incierta de forma fraudulenta) y la única acción de que dispondrá la otra parte será por
                incumplimiento de contrato de conformidad con lo dispuesto en las presentes Condiciones. </p>
            <p><strong>DERECHO A MODIFICAR ESTAS CONDICIONES.</strong> La Empresa tiene el derecho de revisar y
                modificar las presentes Condiciones en cualquier momento. Usted estará sujeto a las políticas y
                Condiciones vigentes en el momento en que use la presente página web o efectúe cada pedido, salvo que
                por ley o decisión de organismos gubernamentales se deban hacer cambios con carácter retroactivo en
                dichas políticas, Condiciones o Declaración de Privacidad, en cuyo caso, los posibles cambios afectarán
                también a los pedidos que usted hubiera hecho previamente.</p>
            <p><strong>LEGISLACIÓN APLICABLE Y JURISDICCIÓN.</strong> El uso de este sitio web y los contratos de compra
                de productos a través del mismo se regirán por la legislación mexicana. Cualquier controversia que surja
                o guarde relación con el uso del sitio web o con dichos contratos será sometida a la jurisdicción de los
                juzgados y tribunales de la Ciudad de México, renunciando expresamente a cualquier otro fuero que
                pudiere corresponderles en razón de sus domicilios presentes o futuros o por cualesquiera otra causa. Si
                usted está contratando como consumidor, nada en la presente cláusula afectará a los derechos que como
                tal le reconoce la legislación vigente.</p>
            <p><strong>COMENTARIOS Y SUGERENCIAS.</strong> Sus comentarios y sugerencias serán bien recibidos. Le
                rogamos enviar tales comentarios y sugerencias a través de las vías de contacto de este sitio web. Si
                usted como consumidor considera que sus derechos han sido vulnerados, puede dirigirnos sus quejas a
                través de las vías de contacto de este sitio web con el fin de solicitar una solución extrajudicial de
                controversias.</p>

        </div>
    );
};

export default TOSPage;