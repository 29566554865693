import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import './App.css';
import FontsGlobal from './components/FontsGlobal';
import Home from './pages/Home';
import TOSPage from './pages/Tos';
import PrivacyPolicyPage from './pages/Privacy';

function App() {

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-T2CG2JLB',
    };
    TagManager.initialize(tagManagerArgs);
  }, []);


  return (
    <Router>
      <FontsGlobal />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terminos-y-condiciones" element={<TOSPage />} />
        <Route path="/aviso-de-privacidad" element={<PrivacyPolicyPage />} />
      </Routes>
    </Router>
  );
}


export default App;
