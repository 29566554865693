import React from 'react';

const PrivacyPolicyPage: React.FC = () => {
    return (
<div
	className='privacy-policy'
>
	<h1>AVISO DE PRIVACIDAD</h1>
	<p><strong>1. IDENTIDAD Y DOMICILIO DEL RESPONSABLE</strong> <br />Nice Like That, SA de C.V., (nombre comercial
		Gotham City™ Midnight) con domicilio en calle Lago Zurich No. 245, Local B-02-A, Col. Ampliación Granada,
		Alcaldía Miguel Hidalgo, Código Postal 11529, Ciudad de México. </p>

	<p><strong>2. DATOS PERSONALES QUE SE REQUERIRÁN</strong><br />
		Los datos personales que se obtengan de manera verbal, por escrito, o a través de medios electrónicos, de
		conformidad con las finalidades para las que otorgue su consentimiento, serán los siguientes:</p>
	<p>
	</p>
	<p>
	</p>
	<ol type="a">
		<li>Datos de identificación</li>
		<li>Datos financieros / patrimoniales.</li>
		<li>Datos personales sensibles: estado de salud, datos ópticos, biométricos (huella dactilar y reconocimiento
			facial). </li>
	</ol>
	<p></p>
	<p><strong>3. FINALIDADES DEL TRATAMIENTO DE LOS DATOS PERSONALES. </strong><br />
		Los datos personales que se obtengan serán utilizados para realizar las siguientes finalidades: </p>

	<p>Finalidades Primarias: (a) Verificar su identidad e información; (b) Integrar su expediente; (c) Actualizar su
		información en nuestra base de datos; (d) Identificarle y/o registrarle como cliente o usuario de nuestro sitio;
		(e) Prestar los servicios solicitados y llevar a cabo la venta de productos solicitados por usted (incluyendo
		sin limitar los servicios de venta física, por teléfono e internet); (f) Entregarle en sucursal, evento y/o a
		domicilio de sus productos y/o prestarle los servicios adquiridos; (j) Informarle y/o contactarle por cualquier
		medio óptico, sonoro, tecnológico o físico (WhatsApp, SMS, teléfono, correo electrónico, mensajería, entre
		otros), respecto al estatus de sus compras y/o entrega de sus productos o servicios; (k) Devolución y/o cambio
		de productos; (l) Emisión de facturas; (m) Videovigilancia dentro de nuestras instalaciones para su seguridad y
		la del personal que se encuentra en ellas; (n) Cumplir con la normatividad aplicable y requerimientos de las
		autoridades con base en Ley para el cumplimiento de las disposiciones legales y requerimientos de diversas
		autoridades; (s) Conservar su información para el cumplimiento de las disposiciones legales y requerimientos de
		diversas autoridades y/o entidades regulatoria; (o) Cumplimiento de las disposiciones de conocimiento del
		cliente y prevención de lavado de dinero; (p) Atender sus dudas, quejas, aclaraciones, reclamaciones y
		sugerencias como cliente; (q) Brindarle atención personal a través de distintos medios de comunicación
		electrónica como correo electrónico, teléfono, chat, entre otros; (r) Realizar grabaciones de las comunicaciones
		que tengamos con usted; y (s) Llevar un histórico sobre sus compras y uso de nuestros servicios.
		Finalidades Secundarías: Adicionalmente, su información personal podrá́ ser utilizada con las siguientes
		finalidades: (a) Enviarle por diversas medias nuestras promociones, boletines, noticias, agradecimientos,
		felicitaciones, ofertas derivado a la relación con nosotros; (b) Llevar a cabo encuestas de calidad y
		satisfacción, para evaluar la calidad de los productos y servicios; (c) Invitarle a participar en nuestros
		eventos, campanas, rifas, sorteos y concursos, en caso de resultar ganador contactarlo para entregar del premio;
		(d) Realizar publicidad en redes sociales, medios de comunicación e información, periódicos y revistas; (e)
		Ofrecerle cualquiera de nuestros servicios y productos; (f) Fines mercadológicos, de prospección comercial y
		fines estadísticos e históricos; (g) Elaborar análisis de uso de productos, segmentación de mercado,
		estadísticas, registros y análisis de información; y (h) Fines de uso de imagen en medios publicitarios y de
		apoyo, imagen corporativa, mercadotecnia, campañas publicitarias, videos/fotografía y publicaciones en cualquier
		otro medio de comunicación.
		El titular de los datos personales tiene derecho a oponerse a que sus datos personales sean usados para los
		fines contenidos en el inciso B anterior. En caso de que desee ejercer este derecho, el titular de los datos
		personales deberá́ hacerlo conforme al procedimiento establecido en el inciso IV del presente Aviso de
		Privacidad, dentro de los 5 días hábiles siguientes a la fecha en la que el presente Aviso de Privacidad haya
		sido puesto a su disposición, a efecto de que el responsable no trate sus datos para dichos fines. En caso
		contrario, se entenderá́ que el titular consiente el tratamiento de sus datos para todos los fines enumerados
		anteriormente. Hacemos notar que lo anterior es sin perjuicio de lo señalado en el artículo 26 de la Ley y demás
		disposiciones aplicables.</p>

	<p>La negativa para el uso de sus datos personales sobre las Finalidades Secundarias no será́ motivo para que le
		neguemos nuestros servicios. </p>

	<p><strong>4. OPCIONES O MEDIOS OFRECIDOS POR EL RESPONSABLE PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS
			PERSONALES.</strong></p>

	<p>Para limitar el uso o divulgación de sus datos personales, el titular deberá́ enviar un correo electrónico,
		dirigido a la Oficina de Privacidad de la Información, a la siguiente dirección electrónica:
		hi@thisisnicelikethat.com, solicitando la limitación del uso o divulgación de que se trate, donde podrá́
		solicitar se le incluya en los listados de exclusión internos de no contactar para fines promocionales y mercado
		lógicos. Asimismo, podrá́ inscribirse en el registro público para evitar publicidad de la Procuraduría Federal
		del Consumidor (REPEP), accediendo a la siguiente liga, http://repep.profeco.gob.mx/. </p>

	<p>Sin embargo, si el titular de los datos personales limita el uso que el Responsable pueda dar a sus datos
		personales, es posible que puedan no tener conocimiento respecto a los beneficios adicionales relacionados con
		nuestras finalidades secundarias. </p>

	<p><strong>5. MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN (“DERECHOS
			ARCO").</strong> </p>

	<p>De conformidad con la Ley, el titular de los datos personales tiene el derecho de Acceder (“Acceso”) a los datos
		que posee el responsable y a los detalles del tratamiento de los mismos, así́ como a Rectificarlos en caso de
		ser inexactos o incompletos (“Rectificación”); Cancelarlos en los términos de la Ley (“Cancelación”) u Oponerse
		al tratamiento de los mismos para fines específicos (“Oposición”). </p>

	<p>El titular de los datos personales o su representante legal podrán ejercer los derechos descritos anteriormente
		enviando un correo electrónico a la siguiente dirección electrónica: hi@thisisnicelikethat.com, indicando que
		solicita ejercer sus derechos ARCO. Esta solicitud puede ser a través de un escrito libre considerando los
		elementos del artículo 29 de la Ley o bien la Oficina de Privacidad le remitirá́ como respuesta el formato para
		el ejercicio de los derechos ARCO. </p>

	<p>Para la solicitud al ejercicio de sus derechos ARCO, debe considerar los siguientes requisitos: </p>

	<p>Identificación oficial del titular.<br />
		Señalar el correo electrónico para comunicarle la respuesta a su solicitud.<br />
		Descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos
		antes mencionados.<br />
		En caso de que el derecho sea ejercido por un representante, se deberá́ proporcionar una identificación oficial
		y el documento con el que acredite su representación. <br />
		En el caso de que la información proporcionada en la solicitud sea insuficiente o errónea para atenderla, o
		bien, no se acompañen los documentos necesarios e indispensables para su atención, el Responsable podrá́
		solicitarle la información y/o documentación necesaria para dar trámite a la misma conforme al artículo 96 del
		reglamento. El titular contará con 10 días hábiles, para atender dicho requerimiento. El responsable dará́
		contestación a su solicitud en un plazo de 20 (veinte) días hábiles contados a partir de la fecha de la
		recepción de su solicitud, este plazo puede ser ampliado una sola vez por un periodo igual de acuerdo al
		artículo 32 de la Ley. Si la solicitud resulta procedente esta se hará́ efectiva en un periodo de 15 días
		hábiles. </p>

	<p>En caso de ejercer el derecho de acceso, el medio por el cual podrá́ obtener la información o datos personales
		solicitados será́ en copias simples o documentos electrónicos (que serán enviados al correo indicado en su
		solicitud). El responsable, podrá́ negar el acceso a los datos personales, o a realizar la rectificación o
		cancelación o conceder la oposición al tratamiento de los mismos, en los supuestos establecidos en el artículo
		34 de la Ley. El Responsable no estará́ obligado a cancelar los datos personales del titular, bajo los supuestos
		establecidos en el artículo 26 de la Ley. </p>

	<p>Para aclarar dudas sobre el procedimiento y requisitos para el ejercicio de los derechos ARCO, la figura
		facultada para brindar atención es la Oficina de Privacidad de la Información los datos de contacto son los
		siguientes: hi@thisisnicelikethat.com. </p>

	<p><strong>6. REVOCACIÓN DEL CONSENTIMIENTO </strong><br />
		El Titular podrá́ revocar el consentimiento que nos ha otorgado para el tratamiento de sus datos personales en
		cualquier momento, tal y como lo establece el artículo 8 de la Ley, para lo anterior será́ necesario enviar un
		correo electrónico a la Oficina de Privacidad, a la siguiente dirección electrónica: hi@thisisnicelikethat.com
		donde se le informará el procedimiento a seguir. </p>

	<p><strong>7. TRANSFERENCIA DE LOS DATOS PERSONALES QUE SE EFECTÚEN.</strong><br />
		Las transferencias realizadas por el Responsable serán en términos a lo establecidas en el artículo 37 de la
		Ley, y en cumplimiento al Artículo 68 del Reglamento. </p>

	<p><strong>8. USO DE COOKIES. </strong><br />
		Utilizamos cookies para mejorar nuestros servicios y mostrarle publicidad relacionada con sus preferencias de
		navegación y el contenido de sus visitas anteriores. Al visitar nuevamente los sitios web del Responsable, las
		cookies nos pueden permitir personalizar nuestro contenido de acuerdo con sus preferencias.</p>

	<p>Hacemos notar que las páginas de internet del Responsable no usan o guardan cookies para obtener datos de
		identificación personal de la computadora del Titular que no se hayan enviado originalmente como parte de la
		cookie. Aunque la mayoría de los navegadores aceptan “cookies”, el titular puede configurar su navegador para
		que no los acepte. Estas tecnologías pueden deshabilitarse siguiendo los procedimientos respectivos a cada
		navegador, sin embargo, debe tomar en cuenta que desactivarlas podría limitar el funcionamiento que ofrece la
		plataforma. </p>

	<p><strong>9. NOTIFICACIÓN DE CAMBIOS / ACTUALIZACIONES AL AVISO DE PRIVACIDAD. </strong><br />
		El presente Aviso de Privacidad podrá́ ser modificado y/o actualizado en cualquier momento por el Responsable,
		para la atención de reformas legislativas o jurisprudenciales, políticas internas, o nuevos requerimientos,
		siendo la versión actualizada la que se encuentre publicada en esta página, la aplicable en todo momento.</p>

	<p>En caso de que el Responsable cambie su identidad, requiera recabar datos personales patrimoniales o financieros
		adicionales, cambie las finalidades para la obtención de sus datos personales o modifique las condiciones de las
		transferencias que se puedan efectuar bajo lo establecido por el presente Aviso de Privacidad, se pondrá́ a
		disposición de los titulares de los datos un nuevo aviso de privacidad, a través del correo electrónico que el
		titular haya proporcionado al Responsable o por medio de la página de internet del Responsable.</p>
</div>
    );
};

export default PrivacyPolicyPage;