
import React, { useRef, useState, useEffect } from 'react';
import PageScroll from 'react-page-scroll';
import { Modal } from 'antd';
import TOSPage from './Tos';
import PrivacyPolicyPage from './Privacy';
import { isDesktop, isIPad13, isIOS } from 'react-device-detect';
import {
        AiFillFacebook,
        AiFillInstagram
    } from "react-icons/ai";

import '../Home.scss';

const Home = () => {
    
    const btnGetTicketsRef_00 = (useRef<HTMLButtonElement>(null));
    const btnGetTicketsRef_01 = (useRef<HTMLButtonElement>(null));
    const btnGetTicketsRef_02 = (useRef<HTMLButtonElement>(null));
    const btnGetTicketsRef_03 = (useRef<HTMLButtonElement>(null));
    const slideRefs = useRef<HTMLDivElement[]>([]);
    const videoRefs = useRef<HTMLVideoElement[]>([]);
    const detailRefs = useRef<HTMLDetailsElement[]>([]);
    const [isVideoLoaded, setVideoLoaded] = useState<boolean[]>([false]);
    const [openDetailIndex, setOpenDetailIndex] = useState<number | null>(null);

    const [isPrivacyModalVisible, setPrivacyModalVisible] = useState(false);
    const [isTermsModalVisible, setTermsModalVisible] = useState(false);

    const showPrivacyModal = () => {
        setPrivacyModalVisible(true);
        document.body.style.overflow = 'hidden';
    };

    const hidePrivacyModal = () => {
        setPrivacyModalVisible(false);
        document.body.style.overflow = '';
    };

    const showTermsModal = () => {
        setTermsModalVisible(true);
        document.body.style.overflow = 'hidden';
    };

    const hideTermsModal = () => {
        setTermsModalVisible(false);
        document.body.style.overflow = '';
    };

    useEffect(() => {
        detailRefs.current = Array.from(
            document.querySelectorAll('.faqDetail')
        ) as HTMLDetailsElement[];
        detailRefs.current.forEach((detail, index) => {
            // detail.addEventListener('toggle', () => handleDetailsToggle(index));
        });
    }, []);

    const handleDetailsToggle = (index: number) => {
        setOpenDetailIndex(prevIndex => (prevIndex === index ? null : index));
    }

    useEffect(() => {
        slideRefs.current = Array.from(document.querySelectorAll('.slide')) as HTMLDivElement[];
        videoRefs.current = Array.from(document.querySelectorAll('.video-background')) as HTMLVideoElement[];

    }, []);    

    const handleVideoLoad = (ndex:number) => {
        setVideoLoaded(prevState => {
            const newState = [...prevState];
            newState[ndex] = true;
            return newState;
        });
    };


    return (
        <PageScroll
            width='100vw'
            height='100vh'
            onScrollInit={({currentChildIndex, numberOfChilds, scrollControl}) => {
                console.log('currentChildIndex',currentChildIndex);
                btnGetTicketsRef_00.current?.addEventListener('click', () => {
                    scrollControl.scrollTo(4);
                });
                btnGetTicketsRef_01.current?.addEventListener('click', () => {
                    scrollControl.scrollTo(4);
                });                
                btnGetTicketsRef_02.current?.addEventListener('click', () => {
                    scrollControl.scrollTo(4);
                });
                btnGetTicketsRef_03.current?.addEventListener('click', () => {
                    scrollControl.scrollTo(4);
                });                
                document.addEventListener('click', (event) => {
                    if ((event.target as Element)?.classList?.contains('scrollDown')) {
                        scrollControl.scrollToNext();
                    }
                });
                console.log()
            }}
            // onScrollEnd={({ currentIndex }) => handleIndex(currentIndex)}
        >
            <div className="slide slide_00"> 
                {!isVideoLoaded[0] && <div>Loading...</div>}
                <video
                    onLoadedData={() => handleVideoLoad(0)}
                    width='100vw'
                    height='100vh'
                    className="video-background"
                    autoPlay
                    loop
                    playsInline
                    muted
                >
                    <source 
                        src={`videos/${isDesktop || isIPad13 ? "desktop/00.mp4" : isIOS ? "mobile/00_optimized.mp4" : "mobile/00.m4v"}`} 
                        type="video/mp4" 
                    />
                    Your browser does not support the video tag.
                </video>
                <div className="content">
                    <div className="content-top">
                        <img 
                            src="images/header.svg" 
                            alt="Gotham City Midnight" 
                            className='header-img'
                        />
                    </div>
                    <div className="content-mid">
                        <button
                            className='btnBig'
                            ref={btnGetTicketsRef_00}
                        >
                            Obtén tus boletos ya
                        </button>
                    </div>
                    <div className="content-bot">
                        <div
                            className='moreDetailsContainer'
                        >
                            <p className='moreDetails'>
                                Más detalles
                            </p>
                            <p 
                                className='scrollDown'
                            >
                                Scroll down  &darr;
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide slide_01">
            {!isVideoLoaded[1] && <div>Loading...</div>}
                <video
                    onLoadedData={() => handleVideoLoad(1)}
                    width='100vw'
                    height='100vh'
                    className="video-background"
                    autoPlay
                    loop
                    playsInline
                    muted
                >
                    <source src={`videos/${isDesktop || isIPad13 ? "desktop/01.mp4" : isIOS ? "mobile/01_optimized.mp4" : "mobile/01.m4v"}`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="content">
                    <div className="content-top">
                        <img 
                            src="images/header.svg" 
                            alt="Gotham City Midnight" 
                            className='header-img'
                        />
                    </div>
                    <div className="content-mid">
                        <div className="content-mid-text">
                            <h2>01.</h2>
                            <h3>Wine + Dine</h3>
                        
                            <p>
                                Wayne Foundation Gala tiene preparada para ti y tus acompañantes una cena única del más alto nivel gastronómico: Cinco cursos homenajeando a los personajes más célebres de Gotham City, acompañados con un maridaje de mixología optativo.
                            </p>
                        </div>
                        <br/>
                        <button
                            className='btnBig'
                            ref={btnGetTicketsRef_01}
                        >
                            Obtén tus boletos ya
                        </button>
                    </div>
                    <div className="content-bot">
                        <div
                            className='moreDetailsContainer'
                        >
                            <p className='moreDetails'>
                                Más detalles
                            </p>
                            <p 
                                className='scrollDown'
                            >
                                Scroll down  &darr;
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide slide_02">
            {!isVideoLoaded[2] && <div>Loading...</div>}
                <video
                    onLoadedData={() => handleVideoLoad(2)}
                    width='100vw'
                    height='100vh'
                    className="video-background"
                    autoPlay
                    loop
                    playsInline
                    muted
                >
                    <source src={`videos/${isDesktop || isIPad13 ? "desktop/02.mp4" : isIOS ? "mobile/02_optimized.mp4" : "mobile/02.m4v"}`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="content">
                    <div className="content-top">
                        <img 
                            src="images/header.svg" 
                            alt="Gotham City Midnight" 
                            className='header-img'
                        />
                    </div>
                    <div className="content-mid">
                        <div className="content-mid-text">
                            <h2>02.</h2>
                            <h3>The Show</h3>
                        
                            <p>
                                En colaboración con las organizaciones civiles de Gotham City, se ha realizado una exhibición multisensorial con luces, sonido y animaciones nunca antes vistas, así como objetos confiscados pertenecientes a los famosos villanos y justicieros de dicha ciudad.
                            </p>
                        </div>
                        <button
                            className='btnBig'
                            ref={btnGetTicketsRef_02}
                        >
                            Obtén tus boletos ya
                        </button>
                    </div>
                    <div className="content-bot">
                        <div
                            className='moreDetailsContainer'
                        >
                            <p className='moreDetails'>
                                Más detalles
                            </p>
                            <p 
                                className='scrollDown'
                            >
                                Scroll down &darr;
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide slide_03">
            {!isVideoLoaded[3] && <div>Loading...</div>}
                <video
                    onLoadedData={() => handleVideoLoad(3)}
                    width='100vw'
                    height='100vh'
                    className="video-background"
                    autoPlay
                    muted
                    loop
                    playsInline
                >
                    <source src={`videos/${isDesktop || isIPad13 ? "desktop/03.mp4" : isIOS ? "mobile/03_optimized.mp4" : "mobile/03.m4v"}`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="content">
                    <div className="content-top">
                        <img 
                            src="images/header.svg" 
                            alt="Gotham City Midnight" 
                            className='header-img'
                        />
                    </div>
                    <div className="content-mid">
                        <div className="content-mid-text">
                            <h2>03.</h2>
                            <h3>The Collection</h3>
                        
                            <p>Tendrás acceso a una selección exclusiva de productos imposible de conseguir en ningún otro momento. Piezas auténticamente de colección con las más sofisticadas creaciones de Gotham City en vestimenta, vinicultura, artículos de lujo y más.</p>
                        </div>
                        <button
                            className='btnBig'
                            ref={btnGetTicketsRef_03}
                        >
                            Obtén tus boletos ya
                        </button>
                    </div>
                    <div className="content-bot">
                        <div
                            className='moreDetailsContainer'
                        >
                            <p className='moreDetails'>
                                Más detalles
                            </p>
                            <p 
                                className='scrollDown'
                            >
                                Scroll down  &darr;
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide slide_04">
                <div className="content">
                    <div className="content-top">
                        <img 
                            src="images/header.svg" 
                            alt="Gotham City Midnight" 
                            className='header-img'
                        />
                    </div>
                    <div className="content-mid">
                        <div>
                            <h1 className='buyTitle'>
                                No pierdas la oportunidad y vive una noche como Bruce Wayne
                            </h1>
                            <h6 className='buySubtitle'>
                                Obtén tus entradas aquí
                            </h6>
                            <p className='buyDescription'>
                                
                                {/* <a 
                                    role='button' 
                                    className='buyLink'
                                    href="https://feverup.com/m/150954"
                                    target="_blank"
                                    rel='noreferrer'
                                >
                                    Ciudad de México
                                </a> */}
                                <a 
                                    role='button'
                                    className='buyLink'
                                    href="https://feverup.com/m/150881"
                                    target="_blank"
                                    rel='noreferrer'
                                >
                                    Guadalajara
                                </a>
                                <a
                                    role='button' 
                                    className='buyLink'
                                    href="https://feverup.com/m/150957"
                                    target="_blank"
                                    rel='noreferrer'
                                >
                                    Monterrey
                                </a>
                                <button 
                                    className='buyComingSoon'
                                >
                                    Más locaciones por confirmar
                                </button>
                            </p>
                            <div className="buyFaq">
                                <details
                                    key={0}
                                    className="faqDetail"
                                    open={openDetailIndex === 0}
                                >
                                    <summary>
                                        ACERCA DE
                                    </summary>
                                    <p className='faqDetailP'>
                                        Gotham City™ Midnight son una serie de eventos oficiales celebrando a Batman con acceso exclusivo y limitado, que giran en torno a este personaje y al universo del cual forma parte.

                                        Se trata de una colaboración única que llega por primera vez a México organizado por NICE LIKE THAT en colaboración con Warner Bros Discovery.
                                    </p>
                                </details>
                                <hr className="faqDivider"/>
                                <details
                                    key={1}
                                    className="faqDetail"
                                    open={openDetailIndex === 1}
                                >
                                    <summary>
                                        ¿QUÉ INCLUYE MI BOLETO?
                                    </summary>
                                    <p className='faqDetailP'>
                                        La oportunidad exclusiva de ser parte por una noche de una Gala de la más alta sociedad de Gotham City, a través de una cena con los más altos estándares gastronómicos acompañada de contenido audiovisual del universo Batman.
                                    </p>
                                </details>
                                <hr className="faqDivider"/>
                                <details
                                    key={2}
                                    className="faqDetail"
                                    open={openDetailIndex === 2}
                                >
                                    <summary>
                                        EDAD IDEAL
                                    </summary>
                                    <p>
                                        El evento se recomienda para personas adultas que quieran disfrutar una cena temática con contenido audiovisual conceptual, así como disfrutar una propuesta gastronómica de los más altos estándares. Se permitirá el acceso a personas mayores de 12 años acompañados de un padre o tutor.
                                    </p>
                                </details>
                                <hr className="faqDivider"/>
                                <details
                                    key={3}
                                    className="faqDetail"
                                    open={openDetailIndex === 3}
                                >
                                    <summary>
                                        ¿PUEDO ASISTIR DISFRAZADO DE BATMAN / MI PERSONAJE FAVORITO?
                                    </summary>
                                    <p>
                                        The Wayne Foundation Gala es la oportunidad única de disfrutar por una noche un evento de la alta sociedad de Gotham City en la vida real, por lo que se espera un código de vestimenta formal por parte de los invitados. Vístete como lo harías para impresionar a Bruce Wayne, sin embargo disfraces y/o cosplay no están permitidos.
                                    </p>
                                </details>
                                <hr className="faqDivider"/>
                                <details
                                    key={4}
                                    className="faqDetail"
                                    open={openDetailIndex === 4}
                                >
                                    <summary>
                                        ¿ESTÁ PERMITIDO EL USO DE CÁMARAS / TOMA DE FOTOGRAFÍAS?
                                    </summary>
                                    <p>
                                        Aunque será permitido el uso de cámaras de smartphone durante ciertos segmentos de la Gala para tomar fotos y videos, ningún equipo profesional puede usarse ni se permitirá su acceso. Para prensa acreditada favor de escribir a este correo.
                                    </p>
                                </details>
                            </div>
                        </div>
                    </div>
                    <hr className="lastDivider"/>
                    <div className="content-bot last-slide">
                    <h1 className='buyTitle'>
                                Sponsors
                    </h1>
                    <img src='./images/logos.svg' 
                        alt='Gotham City Midnight | The Wayne Foundation | DC Comics | Warner Bros.'
                        style={{
                            maxHeight: isDesktop||isIPad13?'75px':'50px',
                        }}
                        width={isDesktop||isIPad13?'400':'250'}
                        // height={isDesktop||isIPad13?'75':'50'}
                    />
                <div
                    style={
                        {
                            justifyContent: 'space-between',
                            alignContent: 'center',
                        }
                    }
                >
                    <div
                        className='footer-text-icons'
                    >
                        <a 
                            href="https://www.facebook.com/profile.php?id=61551920053697" 
                            target="_blank"
                            rel="noreferrer"
                            ><AiFillFacebook
								style={{color: '#FFFFFF'}}
							/></a>
                        &nbsp;
                        <a 
                            href="https://www.instagram.com/gothamcitymidnight/" 
                            target="_blank"
                            rel="noreferrer"
                        ><AiFillInstagram 
							style={{color: '#FFFFFF'}}
						/></a>
                    </div>
                    <div
                        className='footer-text'
                        style={{
                            textAlign: 'center',
                            alignContent: 'center',
                            justifyContent: 'center',
                        }}
                    >
                            <img 
                                src="./images/dc.svg" 
                                style={{
                                    maxWidth: '16px',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    marginRight: '5px',
                                }}
                                alt="DC Comics. (s23)"
                                width={16}
                                height={16}
                            />&nbsp;BATMAN and all related characters and elements ©&nbsp;&&nbsp;™&nbsp;DC&nbsp;Comics.&nbsp;(s23) | Un&nbsp;proyecto&nbsp;de&nbsp;<a href="https://thisisnicelikethat.com/"                             target="_blank"
                            rel="noreferrer"><u>Nice&nbsp;Like&nbsp;That</u></a>.

                        
                            <div
                                className='footer-text'
                                style={{
                                    marginTop: isDesktop||isIPad13?'0px':'2px',
                                    marginBottom: isDesktop||isIPad13?'0px':'6px',
                                    paddingTop: isDesktop||isIPad13?'0px':'6px',
                                    paddingBottom: isDesktop||isIPad13?'0px':'6px',
                                    display: isDesktop || isIPad13 ?'flex':'inline',
                                    justifyContent: 'center',
                                }}
                            >
                                <a 
                                    href='/terminos-y-condiciones'
                                    onClick={(e) => { e.preventDefault(); showTermsModal(); }}
                                >
                                    Términos&nbsp;y&nbsp;condiciones
                                </a>
									&nbsp; | &nbsp;
								<a
									href='/aviso-de-privacidad'
									onClick={(e) => { e.preventDefault(); showPrivacyModal(); }}
								>
                                    Aviso&nbsp;de&nbsp;privacidad
                                </a>
                            </div>
                        

                    </div>
                </div>

                    </div>
                </div>
            </div>
            <Modal 
                title={null} 
                open={isPrivacyModalVisible} 
                onOk={hidePrivacyModal} 
                onCancel={hidePrivacyModal}
                footer={null}
                width={window.innerWidth < 1100 ? '98%' : '90%'}
                className='batiModal'
                style={{ top: 40, zIndex: 99999999999999 }}      
            >
                <PrivacyPolicyPage />
            </Modal>
            <Modal 
                title={null} 
                open={isTermsModalVisible} 
                onOk={hideTermsModal} 
                onCancel={hideTermsModal}
                footer={null}
                width={window.innerWidth < 1100 ? '98%' : '90%'}
                className='batiModal'
                style={{ top: 40, zIndex: 99999999999999 }}
            >
                <TOSPage />
            </Modal>
        </PageScroll>
        
    );
};

export default Home;